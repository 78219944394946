@mixin border-radius($radius) {
  border-radius: $radius;
  border-radius: $radius;
  border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadowinfo) {
  box-shadow: $shadowinfo;
  box-shadow: $shadowinfo;
  box-shadow: $shadowinfo;
  box-shadow: $shadowinfo;
}

@mixin transform($transform) {
  transform: $transform;
  transform: $transform;
  transform: $transform;
  transform: $transform;
  transform: $transform;
}

@mixin transition($transition) {
  transition: $transition;
  transition: $transition;
  transition: $transition;
  transition: $transition;
}

@mixin three-dots-overflow() {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin navbar-link-color($textcolor, $navbarcolor, $opacity) {
  .navbar-brand,
  .navbar-brand:hover,
  .navbar-brand:active,
  .navbar-brand:focus {
    color: $textcolor;
  }

  .nav > li > a:hover,
  .nav > li > a:focus,
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: transparentize($navbarcolor, $opacity);
  }

  .nav > li > a {
    color: $textcolor;
  }

  .bars {
    float: left;
    padding: 10px 20px;
    font-size: 22px;
    color: $textcolor;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 4px;
  }

  .bars:hover {
    background-color: rgb(0 0 0 / 8%);
  }
}
