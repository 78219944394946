/*
 *  Document   : _labels.scss
 *  Author     : RedStar Template
 *  Description: This scss file for label style classes
 */
.label {
  @include border-radius(10px);

  padding: 2px 10px;
  color: #fff;
  display: inline-block;
}

.label-default {
  background-color: #777;
}

.label-primary {
  background-color: #1f91f3;
}

.label-success {
  background-color: #2b982b;
}

.label-info {
  background-color: #00b0e4;
}

.label-warning {
  background-color: #ff9600;
}

.label-danger {
  background-color: #fb483a;
}
