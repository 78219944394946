/*
 *  Document   : theme-black.scss
 *  Author     : RedStar Template
 *  Description: This scss file for black theme style classes
 */
@import "_theme-color-variables";

.theme-black {

  .btn.inactive {
    background-color: #232b3e !important;
    color: #fff;
    border: 1px solid rgba(235, 234, 234, 0.6) !important;
  }

  .btn.inactive:hover,
  .btn.inactive:focus,
  .btn.inactive:active,
  .btn-primary:active {
    background-color: #30a7b6 !important;
  }



  .navbar {
    background-color: transparent;

    .icon-color {
      color: #fff;
    }

    .navbar-nav.navbar-right .user_profile span {
      color: #fff;
    }
  }

  .navbar.active {
    background: #485563;

    /* fallback for old browsers */
    background: linear-gradient(to right, #1c212d, #1a202e);

    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1c212d, #1a202e);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);

    .nav {
      >li {
        >a {
          color: #fff;
        }
      }
    }

    .collapse-menu-icon .mat-icon {
      color: #fff;
    }

    .navbar-right .nav-notification-icons .mat-icon {
      color: #fff;
    }
  }

  .navbar-brand {
    color: $theme-black-navbar-brand;

    &:hover {
      color: $theme-black-navbar-brand_hover;
    }

    &:active {
      color: $theme-black-navbar-brand_active;
    }

    &:focus {
      color: $theme-black-navbar-brand_focus;
    }
  }

  .nav {
    >li {
      >a {
        &:hover {
          background-color: $theme-black-nav-anchor_hover;
          text-decoration: none;
        }

        &:focus {
          background-color: $theme-black-nav-anchor_focus;
          text-decoration: none;
        }
      }
    }

    .open {
      >a {
        background-color: $theme-black-nav-anchor-opened;

        &:hover {
          background-color: $theme-black-nav-anchor-opened_hover;
        }

        &:focus {
          background-color: $theme-black-nav-anchor-opened_focus;
        }
      }
    }
  }

  .bars {
    color: $theme-black-bar;
  }

  .sidebar {
    .menu {
      .list {
        li {
          &.active {
            background-color: $theme-black-menu-list-active;
          }

          a {
            transition: all 0.3s;
            transition: all 0.3s;
            transition: all 0.3s;
            transition: all 0.3s;

            i,
            span {
              transition: all 0.3s;
              transition: all 0.3s;
              transition: all 0.3s;
              transition: all 0.3s;
            }

            &:hover {
              color: $theme-black-sidebar-menu-hover;

              // i,
              // span {
              //   color: $theme-black-sidebar-menu-hover;
              // }
            }
          }
        }

        .ml-menu {
          background-color: $theme-black-menu-list-submenu;
        }
      }
    }

    .legal {
      background-color: $theme-black-legal-bg;

      .copyright {
        a {
          color: $theme-black !important;
        }
      }
    }
  }

  .breadcrumb li a {
    color: $theme-black !important;
  }

  .page-item.active .page-link {
    background-color: $theme-black;
    border-color: $theme-black;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 20%);
  }

  .btn-primary {
    background-color: $theme-black-button-color;
    color: #fff;
    border-color: $theme-black !important;

    &:hover {
      background-color: $theme-black !important;
      color: #fff !important;
    }

    &:active {
      background-color: $theme-black;
      color: #fff;
    }

    &:focus {
      background-color: $theme-black;
      color: #fff !important;
    }

    &:disabled {
      background-color: $theme-black !important;
      color: #fff !important;
    }
  }

  .btn-outline-primary {
    background: 0 0 !important;
    color: $theme-black !important;
    border: 1px solid $theme-black !important;

    &:hover {
      background: $theme-black !important;
      color: #fff !important;
      border: 1px solid $theme-black !important;
    }
  }

  .timelineLabelColor strong {
    color: $theme-black !important;
  }

  .top-sidebar {
    .horizontal-menu {
      li {
        &.active {
          >a {
            color: $theme-black;
          }
        }

        a {
          transition: all 0.3s;
          transition: all 0.3s;
          transition: all 0.3s;
          transition: all 0.3s;

          &:hover {
            color: $theme-black;
          }
        }
      }
    }
  }

  .nav-tabs>li>a::before {
    border-bottom: 2px solid $theme-black;
  }
}

/* Logo Header Background Color */
.logo-black {
  .navbar-header {
    background-color: #1a202e;
    border-right: 1px solid #323538;
  }
}
