/* You can add global styles to this file, and also import other style files */
@import "@ctrl/ngx-emoji-mart/picker";
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

mat-cell span.ellipsis {
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

textarea {
  resize: both !important;
}

@font-face {
  font-family: "arrus-bt-bold";
  font-weight: 700;
  font-style: normal;
  color: red;
  src:
    url("https://www.dafontfree.net/data/22/a/111406/Arrus-BT-Bold.ttf") format("woff"),
    url("https://www.dafontfree.net/data/22/a/111406/Arrus-BT-Bold.ttf") format("opentype"),
    url("https://www.dafontfree.net/data/22/a/111406/Arrus-BT-Bold.ttf") format("truetype");
}

/* Extra small devices (phones, 576px and down) xs*/
@media only screen and (max-width: 576px) {
  .mdc-data-table__table.mat-mdc-table {
    min-width: 800px;
  }

  .materialTableHeader {
    min-width: 800px;
  }

  .mat-table-inside {
    min-width: 1400px;
  }

  mat-cell:first-child,
  mat-header-cell:first-child {
    padding-left: 0;
  }

  .uploadbutton {
    margin-top: 0px;
  }

  .mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) sm*/
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .mdc-data-table__table.mat-mdc-table {
    min-width: 800px;
  }

  .materialTableHeader {
    min-width: 800px;
  }

  .mat-table-inside {
    min-width: 1400px;
  }

  mat-cell:first-child,
  mat-header-cell:first-child {
    padding-left: 0;
  }

  .uploadbutton {
    margin-top: 0px;
  }

  .mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

/* Medium devices (landscape tablets, 768px and up) md */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mdc-data-table__table.mat-mdc-table {
    min-width: 800px;
  }

  .materialTableHeader {
    min-width: 800px;
  }

  .mat-table-inside {
    min-width: 1400px;
  }

  mat-cell:first-child,
  mat-header-cell:first-child {
    padding-left: 0;
  }

  .mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.disable-user {
  pointer-events: none;
  opacity: 0.5;
}
