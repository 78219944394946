.notice-board {
  display: flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;

  .notice-body {
    font-size: 13px;
    padding: 0 0 5px 10px;

    p {
      margin-bottom: 0;
    }

    .notice-heading {
      margin: 5px 0 0;
    }
  }
}
