/*
 *  Document   : theme-beige.scss
 *  Author     : RedStar Template
 *  Description: This scss file for beige theme style classes
 */
@import "_theme-color-variables";

.theme-beige {
  .navbar {
    background-color: transparent;

    .icon-color {
      color: #fff;
    }

    .navbar-nav.navbar-right .user_profile span {
      color: #fff;
    }
  }

  .navbar.active {
    // background-color: $theme-beige !important;
    background: #bd8f67;
    /* fallback for old browsers */
    background:
      linear-gradient(to right,
        #cda55a,
        #d4b493);
    /* Chrome 10-25, Safari 5.1-6 */

    background:
      linear-gradient(to right,
        #cda55a,
        #d4b493);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);

    .nav {
      >li {
        >a {
          color: #fff;
        }
      }
    }

    .collapse-menu-icon .mat-icon {
      color: #fff;
    }

    .navbar-right .nav-notification-icons .mat-icon {
      color: #fff;
    }
  }

  .nav {
    >li {
      >a {
        &:hover {
          background-color: $theme-beige-nav-anchor_hover;
          text-decoration: none;
        }

        &:focus {
          background-color: $theme-beige-nav-anchor_focus;
          text-decoration: none;
        }
      }
    }
  }

  .bars {
    color: $theme-beige-bar;
  }

  .sidebar {
    .menu {
      .list {
        li {
          &.active {
            background-color: $theme-beige-menu-list-active;
          }

          a {
            transition: all 0.3s;
            transition: all 0.3s;
            transition: all 0.3s;
            transition: all 0.3s;

            i,
            span {
              transition: all 0.3s;
              transition: all 0.3s;
              transition: all 0.3s;
              transition: all 0.3s;
            }
          }
        }

        .ml-menu {
          background-color: $theme-beige-menu-list-submenu;
        }
      }
    }

    .legal {
      background-color: $theme-beige-legal-bg;

      .copyright {
        a {
          color: $theme-beige !important;
        }
      }
    }
  }

  .breadcrumb li a {
    color: $theme-beige !important;
  }

  .page-item.active .page-link {
    background-color: $theme-beige;
    border-color: $theme-beige;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 20%);
  }

  .btn-primary {
    background-color: $theme-beige !important;
    color: #fff !important;
    border-color: $theme-beige !important;

    &:hover {
      background-color: $theme-beige !important;
      color: #fff !important;
    }

    &:active {
      background-color: $theme-beige !important;
      color: #fff !important;
    }

    &:focus {
      background-color: $theme-beige !important;
      color: #fff !important;
    }

    &:disabled {
      background-color: $theme-beige !important;
      color: #fff !important;
    }
  }

  .btn-outline-primary {
    background: 0 0 !important;
    color: $theme-beige !important;
    border: 1px solid $theme-beige !important;

    &:hover {
      background: $theme-beige !important;
      color: #fff !important;
      border: 1px solid $theme-beige !important;
    }
  }

  .timelineLabelColor strong {
    color: $theme-beige !important;
  }

  .top-sidebar {
    .horizontal-menu {
      li {
        &.active {
          >a {
            color: $theme-beige;
          }
        }

        a {
          transition: all 0.3s;
          transition: all 0.3s;
          transition: all 0.3s;
          transition: all 0.3s;

          &:hover {
            color: $theme-beige;
          }
        }
      }
    }
  }

  .nav-tabs>li>a::before {
    border-bottom: 2px solid $theme-beige;
  }
}

/* Logo Header Background Color */
.logo-beige {
  .navbar-header {
    background-color: $theme-beige;
  }
}
