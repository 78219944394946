/*
 *  Document   : _leftsidebaroverlay.scss
 *  Author     : RedStar Template
 *  Description: This scss file for left side bar style classes
 */
.overlay {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 10;
}

.overlay-open {
  .sidebar {
    margin-left: 0;
    z-index: 99999999;
  }
}

.sidebar {
  @include transition(all 0.5s);

  font-family: $sidebar-font-family;
  background: #fff;
  width: 260px;
  overflow: hidden;
  display: inline-block;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  border-right: 1px solid #f2f4f9;
  box-shadow: 0 8px 10px 0 rgb(183 192 206 / 20%);
  box-shadow: 0 8px 10px 0 rgb(183 192 206 / 20%);
  z-index: 999 !important;

  .user-info {
    padding: 13px 15px 12px;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    height: 135px;

    .image {
      margin-right: 12px;
      display: inline-block;

      img {
        @include border-radius(50%);

        vertical-align: bottom !important;
      }
    }

    .info-container {
      cursor: default;
      display: block;
      position: relative;
      top: 25px;

      .name {
        @include three-dots-overflow;

        font-size: 14px;
        max-width: 200px;
        color: #fff;
      }

      .email {
        @include three-dots-overflow;

        font-size: 12px;
        max-width: 200px;
        color: #fff;
      }

      .user-helper-dropdown {
        position: absolute;
        right: -3px;
        bottom: -12px;

        @include box-shadow(none);

        cursor: pointer;
        color: #fff;
      }
    }
  }

  .menu {
    position: relative;
    overflow-y: auto;
    height: 100vh;

    .list {
      list-style: none;
      padding-left: 0;

      li {
        &.active {
          .menu-top {
            background-color: #f0f3fb;
          }

          > :first-child {
            span {
              font-weight: 500;
            }
          }
        }

        &.active-top {
          .menu-top {
            background-color: #f0f3fb;
          }
        }
      }

      .header {
        font-size: 12px;
        margin: 45px 0 5px 28px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: #000;
      }

      i.material-icons {
        font-size: 15px;
      }

      i.fas {
        font-size: 15px;
      }

      i.far {
        font-size: 15px;
      }

      i.fab {
        font-size: 15px;
      }

      i.fa {
        font-size: 15px;
      }

      .tooltips .sidebarQuickIcon {
        font-size: 18px;
        margin-top: 10px;
      }

      .active {
        .menu-toggle {
          background: rgb(146 144 144 / 28%);
        }

        .ml-menu {
          display: block;
        }

        .ml-sub-menu {
          display: block;
        }

        .ml-sub-menu2 {
          display: block;
        }

        .ml-sub-menu3 {
          display: block;
        }
      }

      .menu-toggle {
        &::after,
        &::before {
          position: absolute;
          top: calc(50% - 13px);
          right: 17px;
          font-size: 19px;

          @include transform(scale(0));
          @include transition(all 0.3s);
        }

        &::before {
          content: "+";

          @include transform(scale(1));
        }

        &::after {
          content: "\2013";

          @include transform(scale(0));
        }
      }

      .material-icons-two-tone {
        vertical-align: middle;
        filter:
          invert(28%) sepia(39%) saturate(503%) hue-rotate(197deg)
          brightness(94%) contrast(91%);
      }

      .active .menu-toggle {
        &::before {
          @include transform(scale(0));
        }

        &::after {
          @include transform(scale(1));
        }
      }

      .ml-sub-menu {
        &::after,
        &::before {
          position: absolute;
          top: calc(50% - 13px);
          right: 17px;
          font-size: 19px;

          // @include transform(scale(0));
          @include transition(all 0.3s);
        }

        &::before {
          content: "+";

          // @include transform(scale(1));
        }

        &::after {
          content: "\2013";

          // @include transform(scale(0));
        }
      }

      .ml-sub-menu2 {
        &::after,
        &::before {
          position: absolute;
          top: calc(50% - 13px);
          right: 17px;
          font-size: 19px;

          // @include transform(scale(0));
          @include transition(all 0.3s);
        }

        &::before {
          content: "+";

          // @include transform(scale(1));
        }

        &::after {
          content: "\2013";

          // @include transform(scale(0));
        }
      }

      .ml-sub-menu3 {
        &::after,
        &::before {
          position: absolute;
          top: calc(50% - 13px);
          right: 17px;
          font-size: 19px;

          // @include transform(scale(0));
          @include transition(all 0.3s);
        }

        &::before {
          content: "+";

          // @include transform(scale(1));
        }

        &::after {
          content: "\2013";

          // @include transform(scale(0));
        }
      }

      a {
        color: #000;
        position: relative;
        font-size: 14px;
        display: block;
        overflow: hidden;
        line-height: 2rem;
        padding: 9px;
        margin: 8px 11px 0;
        border-radius: 0.5rem;

        &:hover,
        &:active {
          text-decoration: none !important;
          background-color: #f0f3fb;
        }

        small {
          position: absolute;
          top: calc(50% - 7.5px);
          right: 15px;
        }

        span {
          margin: 7px 0 7px 12px;
          color: #333;

          // font-weight: bold;
          font-size: 14px;
          overflow: hidden;
        }
      }

      .ml-menu {
        list-style: none;
        display: none;
        padding-left: 0;

        span {
          font-weight: normal;
          font-size: 14px;
          margin: 3px 0 1px 6px;
        }

        li {
          a {
            padding-left: 45px;
            padding-top: 4px;
            padding-bottom: 4px;
          }

          &.active {
            a.toggled:not(.menu-toggle) {
              &::before {
                content: "";
                display: block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                position: absolute;
                left: 18%;
                top: 50%;
                transform: translate(-10px, -50%);
                transform: translate(-10px, -50%);
                transform: translate(-10px, -50%);
                transform: translate(-10px, -50%);
                transform: translate(-10px, -50%);
                opacity: 1;
                transition: all 0.2s ease;
                transition: all 0.2s ease;
                transition: all 0.2s ease;
                transition: all 0.2s ease;
              }
            }

            .ml-menu {
              &::before {
                content: "\f105";
                font-family: "Font Awesome 5 Free";
                font-size: 13px;
                display: block;
                width: 7px;
                height: 7px;
                position: absolute;
                left: 8%;
                font-weight: 900;
                top: calc(50% - 15px);
              }
            }

            .ml-menu-2 {
              display: block;
            }
          }

          .ml-menu {
            li {
              a {
                padding-left: 80px;
              }
            }

            .ml-menu {
              li {
                a {
                  padding-left: 95px;
                }
              }
            }
          }
        }

        .activeSub {
          .ml-menu-2 {
            display: block;
          }

          .ml-menu-3 {
            display: block;
          }
        }
      }

      .ml-menu-2 {
        list-style: none;
        display: none;
        padding-left: 0;

        span {
          font-weight: normal;
          font-size: 14px;
          margin: 3px 0 1px 6px;
        }

        li {
          a {
            padding-left: 65px;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }

        li.active .ml-menu-3 {
          display: block;
        }

        li.active .ml-menu2::before {
          content: "\f105";
          font-family: "Font Awesome 5 Free";
          font-size: 13px;
          display: block;
          width: 7px;
          height: 7px;
          position: absolute;
          left: 20%;
          font-weight: 900;
          top: calc(50% - 15px);
        }
      }

      .ml-menu-3 {
        list-style: none;
        display: none;
        padding-left: 0;

        span {
          font-weight: normal;
          font-size: 14px;
          margin: 3px 0 1px 6px;
        }

        li {
          a {
            padding-left: 85px;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }

        li.active .ml-menu3::before {
          content: "\f105";
          font-family: "Font Awesome 5 Free";
          font-size: 13px;
          display: block;
          width: 7px;
          height: 7px;
          position: absolute;
          left: 28%;
          font-weight: 900;
          top: calc(50% - 15px);
        }
      }
    }
  }

  .sidebar-badge {
    position: absolute;
    right: 35px;
    padding: 3px 6px;
    margin-top: 5px !important;
    border-radius: 15px;
    color: #fff !important;
    font-size: 12px !important;
    border: none;
    font-weight: 300 !important;
  }
}

.ml-menu {
  .ml-sub-menu {
    &::before {
      @include transform(scale(1));
    }

    &::after {
      @include transform(scale(0));
    }
  }

  .ml-sub-menu2 {
    &::before {
      @include transform(scale(1));
    }

    &::after {
      @include transform(scale(0));
    }
  }

  .ml-sub-menu3 {
    &::before {
      @include transform(scale(1));
    }

    &::after {
      @include transform(scale(0));
    }
  }

  .active .ml-sub-menu {
    &::after {
      @include transform(scale(1));
    }

    &::before {
      @include transform(scale(0));
    }
  }

  .ml-menu-2 {
    li.active .ml-sub-menu2 {
      &::after {
        @include transform(scale(1));
      }

      &::before {
        @include transform(scale(0));
      }
    }
  }

  .ml-menu-3 {
    li.active .ml-sub-menu3 {
      &::after {
        @include transform(scale(1));
      }

      &::before {
        @include transform(scale(0));
      }
    }
  }
}

.right-sidebar {
  width: 280px;
  height: calc(100vh - 60px);
  position: fixed;
  right: -300px;
  top: 60px;
  background: #fdfdfd;
  z-index: 999 !important;

  @include box-shadow(-2px 2px 5px rgba(0, 0, 0, 0.1));

  overflow: hidden;

  @include transition(0.5s);

  &.open {
    right: 0;
  }

  .nav-tabs {
    font-weight: 600;
    font-size: 13px;
    width: 100%;
    margin-left: 2px;

    li {
      text-align: center;

      > a {
        margin-right: 0;
      }

      &:first-child {
        width: 45%;
      }

      &:last-child {
        width: 55%;
      }
    }
  }

  .rightSidebarClose {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 99;
    cursor: pointer;
  }
}

.boxed-layout {
  .sidebar {
    left: auto;
  }

  &.side-closed .sidebar {
    left: 0;
  }
}

.horizontal-menu {
  width: 100%;
  text-align: center;
}

.top-sidebar {
  float: left;
  width: 100%;
  position: fixed;
  z-index: 9;
  top: 60px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
  box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
  box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
  box-shadow: 0 1px 5px rgb(0 0 0 / 30%);

  ul.horizontal-list {
    overflow: visible !important;
    margin: 0;
    display: inline-block;
    height: 60px;

    a {
      color: #747474;
      float: left;
      width: 100%;
    }

    li {
      float: left;
      position: relative;
      padding: 20px;

      &:hover > ul {
        display: block !important;
        position: absolute;
        top: 63px;
        background-color: #fff;
        border-radius: 5px;
        z-index: 9;
        box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
        box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
        box-shadow: 0 1px 5px rgb(0 0 0 / 30%);
        box-shadow: 0 1px 5px rgb(0 0 0 / 30%);

        &::after {
          position: absolute;
          bottom: 100%;
          left: 1.8rem;
          width: 0;
          height: 0;
          margin-left: -16px;
          content: " ";
          pointer-events: none;
          border: solid transparent;
          border-width: 9px;
          border-color: rgb(136 183 213 / 0%);
          border-bottom-color: #fff;
        }

        &::before {
          position: absolute;
          bottom: 100%;
          left: 1.8rem;
          width: 0;
          height: 0;
          margin-left: -16px;
          content: " ";
          pointer-events: none;
          border: solid transparent;
          border-width: 9px;
          border-color: rgb(136 183 213 / 0%);
          border-bottom-color: #ad5454;
        }

        &.mega-ml-menu {
          position: relative;
          top: 0;
        }
      }

      ul li {
        width: 100%;
        padding: 10px 15px;
        text-align: left;
      }
    }

    ul.ml-menu {
      display: none !important;
      width: 200px;

      li:hover ul {
        top: 0;
        left: 100%;
      }

      .menu-toggle {
        position: relative;

        &::after {
          content: "\2023";
          position: absolute;
          right: 0;
          font-size: 25px;
          top: 0;
          line-height: 22px;
        }
      }
    }

    i.material-icons {
      margin-top: 1px;
      margin-right: 3px;
      float: left;
      font-size: 20px;
    }

    // .menu-toggle::after{
    //     content: "\2304";
    //     position: absolute;
    //     top: 15px;
    //     right: 0;
    // }
  }

  .slimScrollDiv {
    overflow: visible !important;
  }
}

// Dark left sidebar style
.menu_dark .sidebar {
  background: #1a202e;
  border-right: 1px solid #2f3a44;
  font-weight: 500;

  .menu .list {
    a {
      color: #cfd8e3;

      span {
        color: #cfd8e3;
      }
    }

    a:hover {
      background-color: rgb(0 0 0 / 20%);
    }

    .header {
      background: transparent;
      color: #9babf1;
      font-size: 12px;
      margin: 45px 0 5px 28px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    .active {
      .menu-toggle {
        background-color: rgb(0 0 0 / 40%);
      }
    }

    .menu-toggle::after {
      color: #b8babb;
    }

    .menu-toggle::before {
      color: #b8babb;
    }

    .material-icons-two-tone {
      filter:
        invert(86%) sepia(3%) saturate(2995%) hue-rotate(185deg)
        brightness(79%) contrast(78%);
    }

    .ml-menu {
      li a {
        color: #dadada;
      }
    }

    li {
      &.active {
        .menu-top {
          background-color: rgb(0 0 0 / 40%);
        }
      }

      &.active-top {
        .menu-top {
          background-color: rgb(0 0 0 / 40%);
        }
      }
    }
  }

  .menu .list .ml-menu .active a::before {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-size: 13px;
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    left: 8%;
    font-weight: 900;
  }
}

.menu_dark {
  .sidebar-userpic-name {
    color: #e6e6e6;
  }

  .profile-usertitle-job {
    color: #e6e6e6;
  }
}

.user-panel {
  float: left;
  width: 100%;
  color: #ccc;
  padding: 25px 0 10px;

  .image {
    width: 35%;
    max-width: 75px;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }
}

.user-img-circle {
  background: #fff;
  z-index: 1000;
  position: inherit;

  // border: 1px solid rgba(52, 73, 94, 0.44);
  padding: 2px;
  box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
}

.img-circle {
  border-radius: 15%;
}

.profile-usertitle {
  text-align: center;
  color: #060606;
}

.profile-usertitle-job {
  font-size: 11px;
  color: #000;
}

.sidebar-userpic-btn {
  display: flex;
  place-content: space-around;
  line-height: 2rem;
  padding: 9px;
  margin: 8px 13px 0;

  a {
    padding: 0 !important;
    margin: 0 !important;
    height: 30px;
    width: 30px;

    &:hover {
      cursor: pointer;
    }

    .mat-button-wrapper {
      margin-left: 0 !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
}

.collapse.in {
  display: block;
  list-style-type: none;
}
