/*
 *  Document   : style.css
 *  Author     : RedStar Template
 *  Description: This is a main style scss file for import all scss files.
 *
 *  Structure (with shortcodes):
                 [1. Common ]
                 [2. Fonts ]
                 [3. Components ]
                 [4. Apps]
                 [5. Pages ]
                 [6. Pugins ]
                 [7. UI ]
                 [8. Browser ]


/*                 [1. Common ]                   			*/
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/* Importing Bootstrap SCSS file. */
@import "common/_variables";
@import "common/_mixins";
@import "common/_customanimate";
@import "common/_general";
@import "common/_demo";
@import "common/_helpers";
@import "common/_media";
@import "common/_animation";
@import "common/_rtl";

/*                 [2. Fonts]                   				*/
// @import "fonts/_material.scss";
@import "fonts/fontawesome/fontawesome";
@import "fonts/fontawesome/regular";
@import "fonts/fontawesome/solid";
@import "fonts/fontawesome/brands";

/*                 [3. Components ]                   		*/
@import "components/_breadcrumbs";
@import "components/_checkboxradio";
@import "components/_dropdownmenu";
@import "components/_feed";
@import "components/_formcomponents";
@import "components/_infobox";
@import "components/_inputformgroup";
@import "components/_labels";
@import "components/_leftsidebaroverlay";
@import "components/_navbar";
@import "components/_navtabs";
@import "components/_noticeboard";
@import "components/_rightsidebar";
@import "components/_searchbar";
@import "components/_switch";
@import "components/_thumbnails";
@import "components/_todo";
@import "components/_settingSidebar";

/*                 [4. Apps]                   				*/
@import "apps/_calendar";
@import "apps/_chat";
@import "apps/_contactlist";
@import "apps/_contactgrid";
@import "apps/_dragdrop";
@import "apps/_task";

/*                 [5. Pages ]                   				*/
@import "pages/_dashboard";
@import "pages/_inbox";
@import "pages/_pricing";
@import "pages/_profile";
@import "pages/_timeline";

// @import "pages/_extra_pages.scss";
@import "pages/_auth";

/*                 [6. Pugins ]                   				*/
@import "plugins/_carousel";
@import "plugins/_charts";
@import "plugins/_formwizard";
@import "plugins/_imagegallery";
@import "plugins/_maps";
@import "plugins/_tables";

/*                 [7. UI ]                   */
@import "ui/_alerts";
@import "ui/_badgelistgroupitem";
@import "ui/_buttons";
@import "ui/_card";
@import "ui/_collapse";
@import "ui/_dialogs";
@import "ui/_expansion";
@import "ui/_mediaobject";
@import "ui/_modals";
@import "ui/_pageloader";
@import "ui/_pagination";
@import "ui/_panels";
@import "ui/_preloaders";
@import "ui/_progressbars";
@import "ui/_slider";
@import "ui/_snackbar";
@import "ui/_tabs";
@import "ui/_tooltippopovers";
@import "ui/_ckeditor";

/*                 [8. Browser ]                   			*/
@import "browser/_ie10";
@import "browser/_ie11";


.theme-black .logo {
  background: url("../images/logo-light.svg") !important;
  display: block !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.logo-white .logo,
.logo {
  background: url("../images/logo-dark.svg");
  display: block !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
