/*
 *  Document   : alert.scss
 *  Author     : RedStar Template
 *  Description: This scss file for alert style classes
 */
.alert {
  @include border-radius(0);
  @include box-shadow(none);

  border: none;
  color: #fff !important;

  .alert-link {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
  }
}

.alert-success {
  background-color: rgb(24 206 15 / 80%);
}

.alert-info {
  background-color: rgb(44 168 255 / 80%);
}

.alert-warning {
  background-color: rgb(255 178 54 / 80%);
}

.alert-danger {
  background-color: rgb(255 54 54 / 80%);
}

.alert-dismissible {
  .close {
    color: #fff;
    opacity: 1;
    border: none;
    text-shadow: none;
  }
}
