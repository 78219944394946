/*
 *  Document   : _maps.scss
 *  Author     : RedStar Template
 *  Description: This scss file for maps style classes
 */

/* Google Maps */
agm-map {
  height: 300px;
}
