.auth-container {
  height: 100%;
  width: 100%;

  .auth-main {
    height: 100%;
    width: 100%;
    margin: 0 !important;
    min-height: 100vh;
  }
}

.left-img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
  position: relative;
  z-index: 1;
}

.left-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  text-align: center;

  h1 {
    color: #fff;
  }

  p {
    color: #fff;
  }
}

.auth-form-section {
  background-color: #fff;

  .validate-form .sign-up-form {
    display: block;
    height: calc(100vh - 27.25rem);
    overflow: auto;
  }
}

.auth-form-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px !important;
  border-radius: 10px;
  cursor: pointer;

  button {
    width: 100%;
  }
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.auth-signup-text {
  font-size: 18px;
  color: #000;
  padding-bottom: 1rem;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.sign-up-link {
  color: #3699ff;
  font-size: 14px !important;
  margin: 0 10px;
}

.auth-wrapper {
  width: 100%;
  max-width: 75%;
  height: 100vh;
  margin-top: auto;
  margin-bottom: auto;
  padding: 2rem 0;
}

.login-title {
  margin-bottom: 20px;
}

.social-login-title {
  font-size: 15px;
  color: #919aa3;
  display: flex;
  margin: 20px 0;

  &::before,
  &::after {
    content: "";
    background-image: linear-gradient(#bbb8b8, #f3f3f3);
    flex-grow: 1;
    background-size: calc(100% - 20px) 1px;
    background-repeat: no-repeat;
  }

  &::before {
    background-position: center left;
  }

  &::after {
    background-position: center right;
  }
}

.welcome-msg {
  font-weight: 500;
  line-height: 1.25;
}

.social-icon {
  text-align: center;

  li a {
    color: #3c4858;
    border: 1px solid #3c4858;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;

    &:hover {
      background-color: #2f55d4;
      border-color: #2f55d4 !important;
      color: #fff !important;
    }
  }
}

.sm-icon {
  height: 16px !important;
  width: 16px !important;
}

.show-pwd-icon {
  color: rgb(0 0 0 / 55%);
  padding: 12px;
}

.face-icon {
  color: rgb(0 0 0 / 55%);
}

.auth-locked {
  font-size: 60px;
  color: #333;
  width: 120px;
  height: 120px;
  background-color: transparent;
  margin: 0 auto;

  img {
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 20%);
  }
}

.auth-locked-title {
  font-size: 30px;
  font-family: Poppins, sans-serif;
  color: #403866;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
}

.error-header {
  font-size: 80px;
  line-height: 1.2;
  color: #403866;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 700;
}

.error-subheader {
  font-size: 17px;
  color: #403866;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 700;
}

.error-subheader2 {
  font-size: 12px;
  color: #919192;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 500;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.auth-spinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: #000;
  animation: spinner 0.8s linear infinite;
}
