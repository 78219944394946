/*
 *  Document   : _timeline.scss
 *  Author     : RedStar Template
 *  Description: This scss file for timeline page style classes
 */

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

/* --------------------------------

      Main components

      -------------------------------- */
header {
  height: 200px;
  line-height: 200px;
  text-align: center;
  background: #303e49;
}

header h1 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
}

.timelineImgHight {
  width: 150px;
}

@media only screen and (min-width: 1170px) {
  header {
    height: 300px;
    line-height: 300px;
  }

  header h1 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

#cd-timeline::before {
  /* this is the vertical line */
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #d7e4ed;
}

@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}

.cd-timeline-block::after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }

  .cd-timeline-block:first-child {
    margin-top: 0;
  }

  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow:
    0 0 0 4px #e6dfdf,
    inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
}

.cd-timeline-img img {
  display: block;
  width: 48px;
  position: relative;
  left: 30%;
  top: 30%;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
}

.cd-timeline-img.cd-picture {
  background: #75ce66;
}

.cd-timeline-img.cd-movie {
  background: #c03b44;
}

.cd-timeline-img.cd-location {
  background: #f0ca45;
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;

    /* Force Hardware Acceleration in WebKit */
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }

  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
    transform: scale(0.5);
    transform: scale(0.5);
    transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    transform: scale(1);
    transform: scale(1);
    transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #f0f1f3;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed;
}

.cd-timeline-content::after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-content h2 {
  color: #303e49;
}

.cd-timeline-content p,
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  font-size: 13px;
  font-size: 0.8125rem;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
  display: inline-block;
}

.cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}

.cd-timeline-content .cd-read-more {
  float: right;
  padding: 0.8em 1em;
  background: #acb7c0;
  color: #fff;
  border-radius: 0.25em;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }

  .cd-timeline-content p {
    font-size: 16px;
    font-size: 1rem;
  }

  .cd-timeline-content .cd-read-more,
  .cd-timeline-content .cd-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }

  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #f0f1f3;
  }

  .cd-timeline-content .cd-read-more {
    float: left;
  }

  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #f0f1f3;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }

  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }

  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
    transform: translateX(-100px);
    transform: translateX(-100px);
    transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    transform: translateX(20px);
    transform: translateX(20px);
    transform: translateX(20px);
    transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
    transform: translateX(0);
    transform: translateX(0);
    transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
    transform: translateX(100px);
    transform: translateX(100px);
    transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    transform: translateX(-20px);
    transform: translateX(-20px);
    transform: translateX(-20px);
    transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
    transform: translateX(0);
    transform: translateX(0);
    transform: translateX(0);
    transform: translateX(0);
  }
}

.timeline {
  list-style: none;
  padding: 0 0 8px;
  position: relative;

  &::before {
    top: 00;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #e7e7e7;
    left: 25px;
    margin-right: -1.5px;
  }

  > li {
    margin-bottom: 5px;
    position: relative;

    &::before {
      content: " ";
      display: table;
    }

    &::after {
      content: " ";
      display: table;
      clear: both;
    }

    > {
      .timeline-panel {
        width: calc(100% - 70px);
        float: right;
        border: 1px solid #e7e7e7;
        padding: 5px 20px;
        position: relative;
        border-radius: 10px;
        margin-bottom: 5px;

        &::before {
          position: absolute;
          top: 26px;
          left: -15px;
          display: inline-block;
          border-top: 15px solid transparent;
          border-right: 15px solid #e7e7e7;
          border-left: 0 solid #e7e7e7;
          border-bottom: 15px solid transparent;
          content: " ";
        }

        &::after {
          position: absolute;
          top: 27px;
          left: -14px;
          display: inline-block;
          border-top: 14px solid transparent;
          border-right: 14px solid #fff;
          border-left: 0 solid #fff;
          border-bottom: 14px solid transparent;
          content: " ";
        }
      }

      .timeline-badge {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 25px;
        left: 8px;

        img {
          border-radius: 50%;
          border: 2px solid #fff;
          box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
          box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
          box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
          box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
        }
      }
    }
  }
}

.timeline-title {
  margin: 4px 0 !important;
  font-size: 13px;
}

.timeline-body > p {
  font-size: 12px;
  margin-bottom: 2px;
}

// Timeline 2

.left-timeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    background: #eee;
    left: 20%;
    margin-left: -6px;
  }

  > li {
    position: relative;

    &:first-child {
      .left-icon {
        background: #fff;
        color: #666;
      }

      .left-time span.large {
        color: #444;
        font-size: 17px !important;
        font-weight: 700;
      }
    }

    &:nth-child(odd) {
      .left-label {
        background: #f0f1f3;

        &::after {
          border-right-color: #f0f1f3;
        }
      }

      .left-time span:last-child {
        color: #444;
        font-size: 13px;
      }
    }

    .left-time {
      display: block;
      width: 23%;
      padding-right: 70px;
      position: absolute;

      span {
        display: block;
        text-align: right;

        &:first-child {
          font-size: 15px;
          color: #3d4c5a;
          font-weight: 700;
        }

        &:last-child {
          font-size: 14px;
          color: #444;
        }
      }
    }

    .left-label {
      margin: 0 0 15px 25%;
      background: #f0f1f3;
      padding: 1.2em;
      position: relative;

      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;

      &::after {
        right: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: #f0f1f3;
        border-width: 10px;
        top: 10px;
      }

      blockquote {
        font-size: 16px;
      }

      .map-checkin {
        border: 5px solid rgb(235 235 235 / 20%);

        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: 0 0 0 1px #ebebeb;
        background: #fff !important;
      }

      h2 {
        margin: 0;
        padding: 0 0 10px;
        line-height: 26px;
        font-size: 16px;
        font-weight: normal;

        a {
          font-size: 15px;

          &:hover {
            text-decoration: none;
          }
        }

        span {
          font-size: 15px;
        }
      }

      p {
        color: #444;
      }
    }

    .left-icon {
      width: 40px;
      height: 40px;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 1.4em;
      line-height: 40px;
      position: absolute;
      color: #fff;
      border-radius: 50%;
      box-shadow: 0 5px 25px 0 rgb(0 0 0 / 20%);
      text-align: center;
      left: 20%;
      top: 0;
      margin: 0 0 0 -25px;

      img {
        border-radius: 50%;
      }
    }

    .empty span {
      color: #777;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .left-timeline > li .left-time {
    padding-right: 60px;
  }
}

@media screen and (max-width: 65.375em) {
  .left-timeline > li .left-time span:last-child {
    font-size: 12px;
  }
}

@media screen and (max-width: 47.2em) {
  .left-timeline {
    &::before {
      display: none;
    }

    > li {
      .left-time {
        width: 100%;
        position: relative;
        padding: 0 0 20px;

        span {
          text-align: left;
        }
      }

      .left-label {
        margin: 0 0 30px;
        padding: 1em;
        font-weight: 400;
        font-size: 95%;

        &::after {
          right: auto;
          left: 20px;
          border-right-color: transparent;
          border-bottom-color: #f5f5f6;
          top: -20px;
        }
      }

      .left-icon {
        position: relative;
        float: right;
        left: auto;
        margin: -64px 5px 0 0;
      }

      &:nth-child(odd) .left-label::after {
        border-right-color: transparent;
        border-bottom-color: #f5f5f6;
      }
    }
  }
}
