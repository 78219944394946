.mat-mdc-snack-bar-container.snackbar-success .mdc-snackbar__surface {
  background-color: rgb(24 206 15 / 80%);
  color: #fff;
}

.mat-mdc-snack-bar-container.snackbar-info .mdc-snackbar__surface {
  background-color: rgb(44 168 255 / 80%);
  color: #fff;
}

.mat-mdc-snack-bar-container.snackbar-warning .mdc-snackbar__surface {
  background-color: rgb(255 230 0 / 80%);
  color: #fff;
}

.mat-mdc-snack-bar-container.snackbar-danger .mdc-snackbar__surface {
  background-color: rgb(255 0 0 / 80%);
  color: #fff;
}
// .mat-mdc-form-field{
//   max-width: 100%;
// }



