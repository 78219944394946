/*
 *  Document   : _contactgrid.scss
 *  Author     : RedStar Template
 *  Description: This scss file for style related to contact grid app
 */
.contact-grid {
  float: left;
  width: 100%;
  text-align: center;
}

.profile-header {
  min-height: 150px;
  color: #fff;
  background-color: #3f51b5 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.user-name {
  padding: 3px;
  font-size: 22px;
  text-align: center;
  padding-top: 10px;
}

.user-img {
  border-radius: 10px;
  max-width: 112px;
  margin-top: -70px;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 30%);
  margin-bottom: 20px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}

.contact-grid .phone .material-icons {
  font-size: 16px;
  padding: 4px 7px 40px 0;
}
